// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Create,
  Edit,
  Filter,
  SimpleShowLayout,
  BooleanInput,
  BooleanField,
  SimpleForm,
  //   ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
  //   ShowButton,
  EditButton,
  //   DeleteButton,
  //   RichTextField,
  SelectInput,
  FileField,
  FileInput,
  DateField,
  Pagination,
  //   TabbedShowLayout, Tab,
  TopToolbar,
  // Toolbar,
  DateInput,
  RadioButtonGroupInput,
  useNotify, useRefresh,
  //   PostFilter,
  //   CreateButton,
  //   ExportButton,
  Button,
  // ChipField,
  // NumberField,
  // useListContext
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { makeStyles, Chip } from '@material-ui/core';
// import TableCell from '@material-ui/core/TableCell';
// import Toolbar from '@material-ui/core/Toolbar';
// import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import ChevronRight from '@material-ui/icons/ChevronRight';

const boolFieldStyle = { lineHeight: '3' }

const dateFormatter = v => {
  // v is a `Date` object
  const v2 = new Date(v)

  if (!(v2 instanceof Date) || isNaN(v2)) return;
  const pad = '00';
  const yy = v2.getFullYear().toString();
  const mm = (v2.getMonth() + 1).toString();
  const dd = v2.getDate().toString();

  const ret = `${yy}-${(pad + mm).slice(-2)}-${(pad + dd).slice(-2)}`;

  // console.log('dateFormatter', ret)

  return ret;
};

const dateParser = v => {
  // v is a string of "YYYY-MM-DD" format
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v);
  if (match === null) return;
  const d = new Date(v);

  // console.log('dateParser 0', d)

  const ret = d.getTime();

  // console.log('dateParser 1', match, ret)

  if (isNaN(ret)) return;

  // console.log('dateParser 2', ret)

  return ret;
};

const useQuickFilterStyles = makeStyles(theme => ({
  chip: {
      marginBottom: theme.spacing(1),
  },
}));

const QuickFilter = ({ label }) => {
  // const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

const DeletedUserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Vorname" source="firstName" alwaysOn resettable />
    <TextInput label="Nachname" source="lastName" alwaysOn resettable />
    <TextInput label="E-Mail" source="email" alwaysOn resettable />
    <QuickFilter label="iOS" source="hasIOSLicense" defaultValue={true} emptyText="iOS" style={boolFieldStyle} />
    <QuickFilter label="Web" source="hasWebLicense" defaultValue={true} emptyText="Web" style={boolFieldStyle} />
    <QuickFilter label="Pyramide" source="canAccessRiesterPyramide" defaultValue={true} emptyText="Pyramide" style={boolFieldStyle} />
    <QuickFilter label="Testversion" source="hasTestLicense" defaultValue={true} emptyText="Testversion" style={boolFieldStyle} />
    
    <DateInput label="Lizenz gültig ab" source="validUntil_gte" format={dateFormatter} parse={dateParser} />
    <DateInput label="Lizenz gültig bis" source="validUntil_lte" format={dateFormatter} parse={dateParser} />

    <QuickFilter source="shouldReceiveNewsletter" label="Newsletter" defaultValue={true} />
    <QuickFilter label="Solvency Newsletter" source="solvency" defaultValue={true} emptyText="Solvency" style={boolFieldStyle} />

    <TextInput label="Direktions Nummer" source="direktionsNummer" resettable />
    <TextInput label="VB Nummer" source="vbNummer" resettable />
    <QuickFilter source="isVIP" label="VIP" defaultValue={true} />
    <QuickFilter source="isSeminarbesucher" label="Seminarbesucher" defaultValue={true} />
    <QuickFilter source="isDeactivated" label="Deactivated" defaultValue={true} />
    <QuickFilter source="isBlocked" label="Blocked" defaultValue={true} />

    <QuickFilter source="terminationDate_lte" label="Gekündigt" defaultValue={"2030-12-31"} />
    <QuickFilter source="terminationDate_unset" label="Nicht gekündigt" defaultValue={"2030-12-31"} />

    <QuickFilter source="onlyMails" label="Nur E-Mail Adressen exportieren" defaultValue={true} />
  </Filter>
);

const deletedUserRowStyle = (record, index) => ({

  backgroundColor: record.validUntil < Date.now() ? ((record.terminationDate) ? '#f7f3da' : '#fecfc5') : ((record.terminationDate) ? '#f7f3da' : '#fff'),
});

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />;

// const PostPagination = () => {
//   const { page, perPage, total, setPage } = useListContext();
//   const nbPages = Math.ceil(total / perPage) || 1;
//   return (
//       nbPages > 1 &&
//           <Toolbar>
//               {page > 1 &&
//                   <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
//                       <ChevronLeft />
//                       Prev
//                   </Button>
//               }
//               {page !== nbPages &&
//                   <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
//                       Next
//                       <ChevronRight />
//                   </Button>
//               }
//           </Toolbar>
//   );
// }

// function customExport () {
//     console.log('filterValues')
//     //javascript goes here to retrieve data from server
//   }

const viewFile = async (url, filter) => {

  fetch(url + filter, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'smmd21#soMachtMandas20!qaywsx'
    }
  }).then((response) => response.blob())
    .then((blob) => {
      var today = new Date();
      const isFilter = (filter !== '' && filter !== '%7B%7D' && !filter.includes('onlyMails'))

      const isOnlyMails = (filter.includes('onlyMails'))

      const a = document.createElement('a');
      a.download = 'dfiav-deletedusers-' + (isOnlyMails ? 'mails-' : '') + (isFilter ? 'filter-' : '') + today.toISOString().substring(0, 10) + '.csv';
      a.href = URL.createObjectURL(blob);
      a.addEventListener('click', (e) => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
      });
      a.click();
    });
};

const DeletedUserListActions = ({ basePath }) => (
  <TopToolbar>
    <DeletedUserFilter context="button" label="Filter hinzufügen" />
    {/* <CreateButton basePath={basePath} /> */}
    {/* <ExportButton /> */}
    <Button onClick={() => {
      const paras = new URLSearchParams(window.location.href).getAll('filter')
      const filter = (paras && paras.length > 0) ? paras[0] : '%7B%7D'
      // console.log('https://rest.dfiav.de/dfiav/users/list/?filter=', filter.trim())
      viewFile('https://rest.dfiav.de/dfiav/deletedusers/list/', filter.trim());
    }}
      label="Export CSV"
      color="primary"
      size="small"
      startIcon={<svg className="MuiSvgIcon-root RaButton-smallIcon-9" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path></svg>}
    />
  </TopToolbar>
);

export const DeletedUserList = (props) => (
  <List {...props}
    filters={<DeletedUserFilter />}
    filter={{ id: '' }}
    sort={{ field: 'lastName', order: 'DESC' }}
    perPage={25}
    pagination={<PostPagination />}
    actions={<DeletedUserListActions />}
    bulkActionButtons={false}
  >
    <Datagrid rowStyle={deletedUserRowStyle} rowClick="edit">
      {/* <TextField source="id" /> */}
      <TextField label="Vorname" source="firstName" />
      <TextField label="Nachname" source="lastName" />
      <TextField source="email" />
      <TextField source="direktionsNummer" label="DNR" />
      <TextField source="vbNummer" label="VBNR" />

      <BooleanField label="iOS" source="hasIOSLicense" />
      <BooleanField label="Web" source="hasWebLicense" />
      <BooleanField label="Pyramide" source="canAccessRiesterPyramide" />
      <BooleanField label="Test" source="hasTestLicense" />

      <BooleanField label="Newsletter" source="shouldReceiveNewsletter" />
      <BooleanField label="Solvency" source="solvency" />

      <DateField label="Lizenz" source="validUntil" />
      {/* <RichTextField source="body" /> */}
      <DateField label="Erstellt" source="created" />
      <DateField label="Gekündigt" source="terminationDate" />
      {/* <TextField source="lastupdate" /> */}
      {/* <ShowButton label="" /> */}
      <EditButton label="" />
      {/* <DeleteButton label="" redirect={false}/> */}
    </Datagrid>
  </List>
);

const DeletedUserTitle = ({ record }) => {
  return <span>Deleted User {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>;
};

const DeletedUserShowActions = ({ basePath, data, resource }) => (
  <div>&nbsp;
    {/* <EditButton basePath={basePath} record={data} />
        <DeleteButton /> */}
  </div>
);

export const DeletedUserShow = (props) => (
  <Show
    actions={<DeletedUserShowActions />}
    title={<DeletedUserTitle />}
    {...props}
  >
    <SimpleShowLayout className="Row">

      <TextField label="ID" source="id" />
      {/* <div className="Row"> */}
      <TextField label="Geschlecht" source="gender" />
      <TextField label="Vorname" source="firstName" />
      <TextField label="Nachname" source="lastName" />
      <TextField label="E-Mail" source="email" />
      {/* </div> */}

      {/* <div className="Row"> */}
      <TextField label="Straße" source="street" />
      <TextField label="Nr" source="streetNumber" />
      <TextField label="PLZ" source="zip" />
      <TextField label="Ort" source="city" />
      {/* </div> */}

      {/* <div className="Row"> */}
      <TextField label="DirektionsNummer" source="direktionsNummer" />
      <TextField label="VB Nummer" source="vbNummer" />
      {/* </div> */}

      {/* <div className="Row"> */}
      <DateField label="Lizenz bis" source="validUntil" />
      <BooleanField label="iOS" source="hasIOSLicense" />
      <BooleanField label="Web" source="hasWebLicense" />
      <BooleanField label="Riester-Pyramide" source="canAccessRiesterPyramide" />
      {/* </div> */}

      {/* <div className="Row"> */}
      <DateField label="Erstellt am" source="created" />
      <DateField label="letzter Login" source="lastLogin" />
      {/* </div> */}
    </SimpleShowLayout>

  </Show>
);

export const DeletedUserCreate = (props) => (
  <Create {...props} >
    <SimpleForm>
      <TextInput source="title" />
      <RichTextInput source="body" />
      <ReferenceInput label="Comment" source="title" reference="comments">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <FileInput source="file" label="File" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
);




//   const MyActions = props => (
//     <TopToolbar>
//       <DeleteButton
//         undoable={false}
//         confirmTitle={'My Title'}  // 'resources.my_res.delete.title'
//         confirmContent={'My Content'}
//       />
//     </TopToolbar>
//   )

export const DeletedUserEdit = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  // const updateIt = useUpdate();
  // const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Änderungen wurden gespeichert.`)
    // redirect('/posts');
    // useUpdate()
    refresh();
  };

  const transform = data => ({
    ...data,
    validUntil: new Date(data.validUntil).getTime()
  });

  // console.log(props);

  return (<Edit
    actions={<DeletedUserShowActions />}
    mutationMode="pessimistic"
    onSuccess={onSuccess}
    transform={transform}
    title={<DeletedUserTitle />}
    {...props}>
    <SimpleForm>
      {/* <ReferenceInput source="id" options={{ disabled: true }} />
      <ReferenceInput source="created" options={{ disabled: true }} /> */}
      <TextField label="ID" source="id" />

      <div className="Row">

        <RadioButtonGroupInput source="gender" choices={[
          { id: 'm', name: 'M' },
          { id: 'w', name: 'W' },
          { id: 'x', name: 'X' },
        ]} />
        <TextInput label="Vorname" source="firstName" required={true} />
        <TextInput label="Nachname" source="lastName" required={true} />
        <TextInput label="E-Mail" source="email" required={true} fullWidth={true} />

      </div>
      <div className="Row">
        <TextInput label="Straße" source="street" required={true} />
        <TextInput label="Nr" source="streetNumber" required={true} />
        <TextInput label="PLZ" source="zip" required={true} />
        <TextInput label="Ort" source="city" required={true} />
      </div>
      <div className="Row">
        <TextInput label="DirektionsNummer" source="direktionsNummer" required={true} />
        <TextInput label="VB Nummer" source="vbNummer" required={true} />
      </div>

      <div className="Row">
        <DateInput label="Lizenz bis" source="validUntil" required={true} format={dateFormatter} parse={dateParser} />
      </div>

      <div className="Row">
        <BooleanInput label="iOS" source="hasIOSLicense" />
        <BooleanInput label="Web" source="hasWebLicense" />
        <BooleanInput label="Riester-Pyramide" source="canAccessRiesterPyramide" />
        <BooleanInput label="Test-Lizenz" source="hasTestLicense" />
      </div>

      <div className="Row">
        <BooleanInput label="Blocked" source="isBlocked" />
        <BooleanInput label="Seminarbesucher" source="isSeminarbesucher" />
        <BooleanInput label="Newsletter" source="shouldReceiveNewsletter" />
        <BooleanInput label="Solvency Newsletter" source="solvency" />
        <BooleanInput label="VIP" source="isVIP" />
      </div>

      <DateField label="Gekündigt zum" source="terminationDate" style={{ backgroundColor: '#f7f3da', padding: '5px' }} />
      <DateField label="Erstellt am" source="created" />
      <DateField label="letzter Login" source="lastLogin" />



    </SimpleForm>
  </Edit>)
};