import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import { Title } from 'react-admin';
import {
    Title,
} from "react-admin";

const DashboardApp = () => (
    <Card>
        <Title title="DFIAV User-Admin" />
        <CardContent>
            <h3>DFIAV User-Admin</h3>
            Firebase Logfiles
            <br />
            <iframe title={"Logview"} src="https://rest.dfiav.de/dfiav/logView" style={{ width: '100%', minHeight: '70vh', height: '100%', border: '0', margin: '0', padding: '0' }} />
        </CardContent>
    </Card>
);

export default DashboardApp